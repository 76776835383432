import React from 'react';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { StaticQuery, graphql } from 'gatsby';
import './IndexPageBody.scss';
import Link from '../Link';
import Api from '../../utils/api';
import NotAMemberComponent from '../NotAMemberComponent';
import SiteTitle from '../PageHeaderTitle';
import { ListOfFeaturesIndex, ReviewIndex } from "./IndexPageFeatures";
import wiredImg from '../../assets/img/wired-grey.svg';
import vpnMentorImg from '../../assets/img/vpn-mentor.svg';
import forbesImg from '../../assets/img/forbes-grey-2.svg';
import bestVpnImg from '../../assets/img/bestvpn.svg';
import bbcImg from '../../assets/img/bbc-grey.svg';
import windowsImg from '../../assets/img/logo-windows.svg';
import appleImg from '../../assets/img/logo-apple.svg';
import androidImg from '../../assets/img/logo-android.svg';
import linuxImg from '../../assets/img/logo-linux.svg';
import routerImg from '../../assets/img/logo-router.svg';
import macosImg from '../../assets/img/mac-os-logo.svg';
import cryptoImg from '../../assets/img/crypto.svg';
import chromeExtensionImg from '../../assets/img/chrome-extension.svg';
import moneyBackImg from '../../assets/img/badge-money-back-300-x-300.svg';
import gdprImg from '../../assets/img/gdpr-compliant-gdpr-copy-7.svg';
import vpnKernelImg from '../../assets/img/vpn-kernel.svg';
import countriesListImg from '../../assets/img/countries-list.png';
import vpnCrossImg from '../../assets/img/vpn-cross.svg';
import classNames from 'classnames';
import mainImg from '../../assets/img/anonine-landing/anonine_main.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';


const query = graphql`
  query {
    anonineMainImage: file(relativePath: { regex: "/anonine_main.jpg/" }) {
        ...fluidImage
    }
    planetImage: file(relativePath: { regex: "/planet.jpg/" }) {
        ...fluidImage
    }
  }
`;


const Grid = props => (
  <div
    className={classNames(props.className, "mdc-layout-grid")}
    style={{
      'mdc-layout-grid-margin-desktop': '24px',
      'mdc-layout-grid-margin-tablet': '12px',
      'mdc-layout-grid-margin-mobile': '12px',
      'max-width': '1232px'
    }}
  >
    <div className="mdc-layout-grid__inner">
      {props.children}
    </div>
  </div>
);


const Cell = (
  {children, className = {}, cellSpan = {desktop: 1, tablet: 1, phone: 1}}
) => (
  <div
    className={classNames(
      className,
      `mdc-layout-grid__cell--span-${cellSpan.desktop}-desktop`,
      `mdc-layout-grid__cell--span-${cellSpan.tablet}-tablet`,
      `mdc-layout-grid__cell--span-${cellSpan.phone}-phone`,
    )}
  >
    {children}
  </div>
);


const FeatureCell = (
  {children, className, cellSpan = {desktop: 6, tablet: 8, phone: 4}}
) => (
  <Cell
    className={classNames(className, "mdc-layout-grid__cell--align-middle")}
    cellSpan={cellSpan}
  >
    {children}
  </Cell>
);


class IndexPageBody extends React.Component {
  constructor(props) {
    super(props);
    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
    this.state = {
      loadingData: true,
      plans: [
        {
          plan_code: "m1",
          title: "1 Month",
          price: "6.99",
          monthly_price: "6.99",
          currency_sign: "$",
          currency_goes_before_price: false,
          savings_percent: 0,
          order_url: "?",
          is_best: false
        },
        {
          plan_code: "m12",
          title: "12 Months",
          price: "47.8",
          monthly_price: "3.99",
          currency_sign: "$",
          currency_goes_before_price: false,
          savings_percent: 43,
          order_url: "?",
          is_best: true
        },
        {
          plan_code: "m3",
          title: "3 Months",
          price: "17.97",
          monthly_price: "5.99",
          currency_sign: "$",
          currency_goes_before_price: false,
          savings_percent: 14,
          order_url: "?",
          is_best: false
        },
      ]
    };
  }

  componentDidMount() {
    axios(this.api.plansConfig()).then(response => {
      if (response.status === 200) {
        console.log('response.data:', response.data);
        this.setState({ plans: response.data.plans, loadingData: false });
      }
    });
  }

  render() {
    const { t, brand } = this.props;

    return (
      <StaticQuery
        query={query}
        render={({ anonineMainImage, planetImage }) => (
          <div className='mainPage'>
            <SiteTitle brandName={brand.name} title={t('page_home:html_title')} />

            <Grid className="feature-privacy">
              <FeatureCell>
                <h1 className='feature-title-h1'>
                  {t('page_home:title_1v2')}
                </h1>
                <p className='feature-text'>
                  {t('page_home:text_1v2')}
                </p>
                <div className='feature-button-wrapper'>
                  <Link
                    className='feature-button main-button_accent'
                    to={this.api.orderUrl()}
                  >
                    {t('page_home:get_anonine_vpn')}
                  </Link>
                </div>
              </FeatureCell>
              <FeatureCell
                className="mdc-layout-grid__cell--align-middle"
                cellSpan={{desktop: 6, tablet: 8, phone: 4}}
              >
                <img alt={t('page_home:img_alt')} src={mainImg} className="img-fluid" />
              </FeatureCell>
            </Grid>

            <div className='main-wrapper main-wrapper-brands main-wrapper_bg_gray mainPage__block'>
              <div className='main-brands'>
                <div className='main-brandsInner'>
                  <img src={wiredImg} alt='Wired' />
                  <img src={vpnMentorImg} alt='Vpn Mentor' />
                  <img src={forbesImg} alt='Forbes' />
                  <img src={bestVpnImg} alt='Best VPN' />
                  <img src={bbcImg} alt='BBC' />
                </div>
              </div>
            </div>

            <Grid className="feature--padding-top">
              <FeatureCell>
                <h1 className='feature-title-h2'>
                  {t('page_home:title_3')}
                </h1>
                <p className='feature-text'>
                  {t('page_home:text_3')}
                </p>
                <div className='feature-button-wrapper'>
                  <Link
                    className='feature-button main-button_dark'
                    to={this.api.serviceUrl()}
                  >
                    {t('page_home:features_btn')}
                  </Link>
                </div>
              </FeatureCell>
              <FeatureCell>
                <img
                  src={vpnKernelImg}
                  alt={t('page_home:crypto_accepted')}
                  className="img-fluid"
                />
              </FeatureCell>
            </Grid>

            <div className="feature-countries">
              <Grid>
                <FeatureCell>
                  <img
                    src={countriesListImg}
                    alt={t('page_home:crypto_accepted')}
                    className="img-fluid feature-countries__image"
                  />
                </FeatureCell>
                <FeatureCell>
                  <div className="feature-countries__text">
                    <h1 className='feature-title-h2'>
                      {t('page_home:title_4')}
                    </h1>
                    <p className='feature-text'>
                      {t('page_home:text_4')}
                    </p>
                    <div className='feature-button-wrapper'>
                      <Link
                        className='feature-button main-button_dark'
                        to={this.api.networkUrl()}
                      >
                        {t('page_home:locations_btn')}
                      </Link>
                    </div>
                  </div>
                </FeatureCell>
              </Grid>
            </div>

            <Grid className="feature--padding-vertical">
              <FeatureCell cellSpan={{desktop: 7, tablet: 8, phone: 4}}>
                <h1 className='main-title_h2'>
                  {t('page_home:title_2')}
                </h1>
                <p className='feature-text'>
                  {t('page_home:text_2')}
                </p>
                <div className="feature-button-wrapper">
                  <Link
                    className='feature-button main-button_dark'
                    to={this.api.applicationsUrl()}
                  >
                    {t('page_home:installation')}
                  </Link>
                </div>
                <div className="feature-button-wrapper main-devices">
                  <Link
                    title={t('page_applications:application_windows')}
                    to={this.api.applicationWindowsUrl()}
                  >
                    <img
                      src={windowsImg}
                      alt={t('page_applications:application_windows')}
                    />
                  </Link>
                  <Link
                    title={t('page_applications:application_macos')}
                    to={this.api.applicationMacOsUrl()}
                  >
                    <img
                      src={macosImg}
                      alt={t('page_applications:application_macos')}
                    />
                  </Link>
                  <Link
                    to={this.api.applicationIosUrl()}
                    title={t('page_applications:application_ios')}
                  >
                    <img
                      alt={t('page_applications:application_ios')}
                      src={appleImg}
                    />
                  </Link>
                  <Link
                    title={t('page_applications:application_android')}
                    to={this.api.applicationAndroidUrl()}
                  >
                    <img
                      alt={t('page_applications:application_android')}
                      src={androidImg}
                    />
                  </Link>
                  <Link
                    title={t('page_applications:application_linux')}
                    to={this.api.applicationLinuxUrl()}
                  >
                    <img
                      alt={t('page_applications:application_linux')}
                      src={linuxImg}
                    />
                  </Link>
                  <Link
                    title={t('page_applications:app_extension')}
                    to={this.api.applicationExtensionUrl()}
                  >
                    <img
                      alt={t('page_applications:app_extension')}
                      src={chromeExtensionImg}
                    />
                  </Link>
                  <Link
                    title={t('page_applications:application_router')}
                    to={this.api.applicationRouterUrl()}
                  >
                    <img
                      alt={t('page_applications:application_router')}
                      src={routerImg}
                    />
                  </Link>
                </div>
              </FeatureCell>
              <FeatureCell cellSpan={{desktop: 5, tablet: 8, phone: 4}}>
                <img
                  src={vpnCrossImg}
                  alt={t('page_home:title_2')}
                  className="img-fluid"
                />
              </FeatureCell>
            </Grid>

            <div className='wrapper-service wrapper-service_bg_gray'>
              <div className='innerWrapper-service'>
                <NotAMemberComponent
                  brand={brand.name}
                  orderUrl={this.api.orderUrl()}
                  text={t('page_service:not_a_member')}
                  buttonText={t('page_service:get_anonine')}
                  nomask
                  noBorder
                  noPadding
                />
              </div>
            </div>
            <h1 className='title-wireguard_h1 title-wireguard_h1_benefits'>
              {t('page_home:cards_title')}
            </h1>
            <div className='wireguard-text-description'>
              {t('page_home:cards_sub_title')}
            </div>
            <div className='wrapper-cards'>
              <div className='
                innerWrapper-cards
                innerWrapper-cards-future
                innerWrapper-cards_mw_1024
              '>
                <ListOfFeaturesIndex />
              </div>
            </div>
            <div className='main-innerWrapper review-wrapper'>
              <ReviewIndex/>
            </div>
            <div className='main-innerWrapper mainPage__block'>
              <div className='innerWrapper'>
                <div className='main-grid4'>
                  <div className='main-security'>
                    <img
                      src={cryptoImg}
                      alt={t('page_home:crypto_accepted')}
                    />
                    <img
                      src={moneyBackImg}
                      alt={t('page_home:money_back')}
                    />
                    <img
                      src={gdprImg}
                      alt={t('page_home:gdpr')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="plan-container">
              <div className='main-innerWrapper'>
                <h1 className='title-wireguard_h1 title-wireguard_h1_benefits'>
                  {t('page_home:plan_title')}
                </h1>
                <div className='wireguard-text-description'>
                  {t('page_home:plan_text')}
                </div>
              </div>
              <div className='plan-innerWrapper'>
                { this.state.plans.map(plan => (
                  <a
                    href={plan.order_url}
                    className={classNames({
                      "plan-item": true,
                      "visible-plan-cont": plan.is_best,
                      "loading-data": this.state.loadingData,
                    })}
                  >
                    <div className="plan-top">
                      <div
                        className={classNames({
                          "plan-item_best": true,
                          "visible-plan": plan.is_best
                        })}
                      >{t('page_home:plan_best')}</div>
                      <div className='plan-item_period'>{plan.title}</div>
                      <div className='plan-item_price'>
                        {plan.currency_goes_before_price ? plan.currency_sign : ""}
                        {plan.monthly_price}
                        {plan.currency_goes_before_price ? "" : plan.currency_sign}
                      </div>
                      <div className='plan-item_per'>{t('page_home:plan_per')}</div>
                    </div>
                    <div className='plan-bottom'>
                      { plan.savings_percent ? (
                        <div className='plan-btn_save'>
                          {t('page_home:plan_save')} {plan.savings_percent}&#37;
                        </div>
                      ) : "" }
                      <div className='plan-item_total'>
                        {t('page_home:plan_order_total')} –&nbsp;
                        {plan.currency_goes_before_price ? plan.currency_sign : ""}
                        {plan.price}
                        {plan.currency_goes_before_price ? "" : plan.currency_sign}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default withNamespaces()(IndexPageBody);
