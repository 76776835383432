import React from 'react';
import cl from 'classnames';
import s from './Review.module.scss';

const Review = ({ className, text, author }) => (
  <div className={cl(className, s.review)}>
    <div className={s.review__inner}>
      <div className={s.review__text} dangerouslySetInnerHTML={{ __html: text }} />
      <div className={s.review__author} dangerouslySetInnerHTML={{ __html: author }} />
    </div>
  </div>
);

export default Review;
