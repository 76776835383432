import React from 'react';
import { withNamespaces } from 'react-i18next';
import ListOfFeatures from "../AppicationsPage/ListOfFeatures";
import Review from "../AppicationsPage/Review";
import card1Img from "../../assets/img/applications/noun-vpn-security-1531495.svg";
import card2Img from "../../assets/img/all-protocols-icon.svg";
import card4Img from "../../assets/img/public-icon.svg";
import card5Img from "../../assets/img/applications/noun-refund-1355939.svg";
import card6Img from "../../assets/img/wireguardPage/perfomance.svg";


export const ListOfFeaturesIndex = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_home:card_title_1'), text: t('page_home:card_text_1'), imgSrc: card1Img },
    { title: t('page_home:card_title_2'), text: t('page_home:card_text_2'), imgSrc: card2Img },
    { title: t('page_home:card_title_3'), text: t('page_home:card_text_3'), imgSrc: '' },
    { title: t('page_home:card_title_4'), text: t('page_home:card_text_4'), imgSrc: card4Img },
    { title: t('page_home:card_title_5'), text: t('page_home:card_text_5'), imgSrc: card5Img },
    { title: t('page_home:card_title_6'), text: t('page_home:card_text_6'), imgSrc: card6Img }
  ]}
  />
));

export const ReviewIndex = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_1')}
    author={t('page_applications:anonymous')}
  />
));
