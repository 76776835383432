import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layouts/index';
import PageHeader from '../components/PageHeader';
import SPageContent from '../styled/PageContent';
import PageFooter from '../components/PageFooter';
import IndexPageBody from '../components/IndexPage/IndexPageBody';

class IndexPage extends React.Component {

  componentDidMount() {
    console.log('componentDidMount');
    window.addEventListener('pageshow', (event) => {
      console.log('pageshow');
      if (event.persisted) {
        console.log('persisted');
        window.location.reload();
      }
    })
  }

  render() {
    const {
      gatsbyUrl,
      pythonUrl,
      brand,
      indexPage,
      affiliateUrl
    } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <div>
          <PageHeader
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
            isMain
          />
          <SPageContent>
            <IndexPageBody
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              brand={brand}
              price={indexPage}
            />
          </SPageContent>
          <PageFooter
            brand={brand}
            location={location}
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            affiliateUrl={affiliateUrl}
          />
        </div>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        indexPage {
            us {
              sign
              amount
            }
            eu {
              sign
              amount
            }
            sv {
              sign 
              amount
            }
        }
        brand {
          name
          nameCom
          twitter
          facebook
        }
        gatsbyUrl
        pythonUrl
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <IndexPage data={data} location={location} />}
  />
);
