import React from 'react';
import cl from 'classnames';
import s from './ListOfFeatures.module.scss';

const ListOfFeatures = ({ className, cards }) => (
  cards.map(({ className, title, imgSrc, text }) => (
    <div className={cl(s.card, className)} key={title}>
      <div className={cl(s.card__imgContainer)}>
        { imgSrc && <img alt={title} src={imgSrc} /> }
      </div>
      <div className={cl(s.card__right)}>
        <div className={cl(s.card__title)} dangerouslySetInnerHTML={{ __html: title }} />
        <div
          className={cl(s.card__text)}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  ))
);

export default ListOfFeatures;
